import * as React from 'react';
import { graphql } from 'gatsby';

import { Template } from  'common/layout';
import { ClassesContent, ClassesHeader } from 'modules/ClassesPage';

const ClassesOverview = () => {
  return (
    <Template>
      <ClassesHeader />
      <ClassesContent />
    </Template>
  );
};

export default ClassesOverview;

export const classesQuery = graphql`
  query Classes($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          language
          data
        }
      }
    }
  }
`;
