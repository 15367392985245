import styled from 'styled-components';

import { media } from 'styles/utils';

export const PageWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.gray.light};
`;

export const Container = styled.div`
  padding: 0 24px;

  ${media.desktop`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    max-width: 1440px;
    margin: 0 auto;
  `}
`;

export const PolicyContainer = styled.div`
  margin-top: 40px;
`;

export const ClassesIntro = styled.div`
  grid-column: 1/5;
  margin-top: 60px;
`;

export const Classes = styled.div`
  grid-column: 6 / 13;
  margin-top: 40px;
`;

export const Span = styled.span`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  padding: 2px;
`;

export const List = styled.ul`
  padding-left: 16px;
`;

export const ListItem = styled.li`
  color: ${({ theme }) => theme.colors.gray.dark};
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1px;
`;

export const ClassesList = styled.div`
  margin-bottom: 40px;
`;

export const SectionHeader = styled.p`
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;

  span {
    font-weight: 400;
  }
`;
