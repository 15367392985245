import * as React from 'react';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import dayjs from 'dayjs';

import { Heading, Text } from 'common/typography';
import { getUserClasses } from 'ducks/me';
import { useDispatch, useSelector, useIntersection } from 'services/hooks';
import { BookingCard } from 'modules/booking';
import {
  meDataSelector, userUpcomingOverviewClassesData, userPastClassesData, userClassesAttendance,
  userEnrolledClassesVisible, userClassesData,
} from 'selectors/me';
import { selectClassCounter } from 'selectors/rewards';

import {
  PageWrapper, Container, PolicyContainer, ClassesIntro, Classes, Span, List, ListItem,
  ClassesList, SectionHeader,
} from './styled';

export const ClassesContent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const firstName = useSelector(meDataSelector)?.firstName;
  const activeBookings = useSelector(userUpcomingOverviewClassesData);
  const pastBookings = useSelector(userPastClassesData);
  const allClassesAmount = useSelector(userClassesData)?.length || 0;
  const enrolledVisible = useSelector(userEnrolledClassesVisible) || 0;
  const enrolledTotal = useSelector(userClassesAttendance)?.enrolled || 0;
  const totalClassCounter = useSelector(selectClassCounter);
  const totalClassValue = totalClassCounter?.currentValue || 0;
  const [enrolled, setEnrolled] = React.useState(0);
  const endOfList = React.useRef<HTMLDivElement>(null);
  const intersection = useIntersection(endOfList);
  const showActiveBookings = activeBookings && activeBookings.length > 0;
  const showPastBookings = pastBookings && pastBookings.length > 0;

  React.useEffect(() => {
    if (intersection && enrolledVisible < enrolled) {
      dispatch(getUserClasses(allClassesAmount + 50));
    }
  }, [intersection, enrolled, enrolledVisible, allClassesAmount]);

  // Using rewards endpoint to count total classes like the app
  // All rewards begin with 1 as default for new users
  // If value equals 1 use old endpoint
  React.useEffect(() => {
    if (totalClassValue === 1) {
      setEnrolled(enrolledTotal);
    }
    else {
      setEnrolled(totalClassValue);
    }
  }, [totalClassValue]);

  return (
    <PageWrapper>
      <Container>
        <ClassesIntro>
          <Text $size={30} $lineHeight="40">
            {t('hey, {{ firstName }}', { firstName })} <br />
            <Trans i18nKey="classes.enrolled" values={{ enrolled: enrolled.toString() }}>
              You have completed <Span>{enrolled.toString()}</Span> class(es).
            </Trans>
          </Text>
          <PolicyContainer>
            <Heading variant="black" as="h3" upperCase>{t('cancel policy title')}</Heading>
            <List>
              <ListItem>{t('cancel policy 1')}</ListItem>
              <ListItem>{t('cancel policy 2')}</ListItem>
            </List>
          </PolicyContainer>
          <PolicyContainer>
            <Heading variant="black" as="h3" upperCase>{t('waiting policy title')}</Heading>
            <Text variant={['gray', 'dark']}>{t('waiting policy')}</Text>
          </PolicyContainer>
        </ClassesIntro>
        <Classes>
          {showActiveBookings &&
            <ClassesList>
              <SectionHeader>{t('Upcoming')}</SectionHeader>
              {activeBookings.map((item) => (
                <BookingCard key={item.attendanceId} item={item} />
              ))}
            </ClassesList>
          }
          {showPastBookings && pastBookings.map((item) => (
            <ClassesList key={item.date}>
              <SectionHeader>
                {dayjs(item.date).format('MMMM')} <span>{dayjs(item.date).format('YYYY')}</span>
              </SectionHeader>
              {item.classes.map((classItem) => (
                <BookingCard key={classItem.attendanceId} item={classItem} />
              ))}
            </ClassesList>
          ))}
        </Classes>
        <div ref={endOfList} />
      </Container>
    </PageWrapper>
  );
};
