import styled from 'styled-components';

import { media } from 'styles/utils';

export const HeaderContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  min-height: 147px;
  padding: 0 24px;
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 36px 0 12px;

  ${media.desktop`
    padding: 36px 0 0;
  `}
`;
